<template>
  <NuxtLink v-if="url" class="icon-link" :to="url" :target>
    <Icon :name="useLinkIcon(url)" class="icon-link__icon" />
    <div class="icon-link__label">
      {{ label }} {{ extension?.length ? `(${useFormatFileMetaData(size, extension)})` : '' }}
    </div>
  </NuxtLink>
  <div v-else class="icon-link">
    <Icon :name="icon" class="icon-link__icon" />
    <div class="icon-link__label">
      {{ label }}
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  label: string,
  url?: any
  icon: string
  size?: number
  extension?: string
}>()

const target = computed(() => {
  if (
    props.url === undefined ||
    typeof props.url === 'object' ||
    (typeof props.url === 'string' && props.url.startsWith('/'))
  ) {
    return null
  }

  return '_blank'
})

</script>

<style lang="scss">
@use "~/assets/sass/tools";

.icon-link {
  display: flex;
  align-items: center;
  gap: calc(var(--grid-gutter) / 2);
  text-decoration: none;
}
</style>
