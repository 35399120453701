export default function (url: string) {
  const fileSufixes = ['txt', 'rtf', 'doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx', 'pdf', 'odf', 'odg', 'odp', 'ods', 'odt', 'fodt', 'fods', 'fodp', 'fodg', 'key', 'numbers', 'pages']

  function endsWithAny (suffixes: string[], string : string) {
    return suffixes.some(function (suffix) {
      return string.endsWith(`.${suffix}`)
    })
  }

  switch (true) {
    case typeof url === 'object':
      return 'CircleArrowRightFilled'
    case url.startsWith('tel:'):
      return 'CirclePhoneFilled'
    case url.startsWith('mailto:'):
      return 'CircleMailFilled'
    case useIsInternalLink(url):
      return 'CircleArrowRightFilled'
    case endsWithAny(fileSufixes, url):
      return 'CircleDownloadFilled'
    default:
      return 'CircleWebsiteFilled'
  }
}
